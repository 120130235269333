import React, { useState, useEffect } from "react";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import "./styles.scss";

const HeroV4 = props => {
  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const [backgroundImage, setBackgroundImage] = useState("");

  useEffect(() => {
    const updateBackgroundImage = () => {
      const screenWidth = window.innerWidth;
      const image = screenWidth >= 768 ? props._rawHeroBgImageDesktop : props._rawHeroBgImage;
      setBackgroundImage(
        image
          ? urlBuilder
              .image(image)
              .auto("format")
              .quality(80)
              .fit("crop")
              .width(1700)
              .height(960)
              .format("webp")
              .url() || ""
          : ""
      );
    };

    updateBackgroundImage();
    window.addEventListener("resize", updateBackgroundImage);

    return () => window.removeEventListener("resize", updateBackgroundImage);
  }, [props._rawHeroBgImage, props._rawHeroBgImageDesktop, urlBuilder]);

  const heroImageSrc = props._rawHeroImage
    ? urlBuilder.image(props._rawHeroImage).auto("format").quality(80).fit("crop").format("webp").url() || ""
    : "";

  return (
    <section
      data-testid="herov4"
      className="rx-hero-v4"
      style={{
        ...(props.bgColor
          ? {
              backgroundColor: `${props.bgColor.value}`
            }
          : null)
      }}
    >
      <div
        className="rx-hero-background-image"
        style={{
          backgroundImage: `url(${backgroundImage})`
        }}
      >
        <div className="content">
          <div className="logo">
            {props._rawHeroImage && (
              <picture>
                <source media={"(min-width: 641px)"} srcSet={heroImageSrc} />
                <img src={heroImageSrc} alt={props._rawHeroImage.alt} className="img-fluid" />
              </picture>
            )}
            <a href="#anchor" className="store-locator-link">
              Consulte as lojas participantes
            </a>

            <span>*Versus antitranspirantes comuns</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroV4;
